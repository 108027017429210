.waiter {
    margin-top: 40vh;
    text-align: center;
}

.waiter-load {
  position: relative;
  margin: 50px auto;
  width: 100px;
  height: 80px;
}

.waiter-gear {
  position: absolute;
  z-index: -10;
  width: 40px;
  height: 40px;
  animation: spin 5s infinite;
}

.waiter-two {
  left: 40px;
  width: 80px;
  height: 80px;
  animation: spin-reverse 5s infinite;
}

.waiter-three {
  top: 45px;
  left: -10px;
  width: 60px;
  height: 60px;
}

@keyframes spin {
  50% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  50% {
    transform: rotate(-360deg);
  }
}

.waiter-lil-circle {
  position: absolute;
  border-radius: 50%;
  box-shadow: inset 0 0 10px 2px gray, 0 0 50px white;
  width: 100px;
  height: 100px;
  opacity: .65;
}

.waiter-blur-circle {
  position: absolute;
  top: -19px;
  left: -19px;
}

.waiter-text {
  color: lightgray;
  font: {
    size: 18px;
    family: 'Open Sans', sans-serif;
  }
  text-align: center;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans);
